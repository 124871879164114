.alert {
  padding: 20px;
  background-color: #f44336;
  color: #fff;
  position: fixed;
  top: 100px;
  right: 0;
  z-index: 1654;
  transition: 0.3;

  .closebtn {
    margin-left: 15px;
    color: white;
    font-weight: bold;
    float: right;
    font-size: 22px;
    line-height: 20px;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      color: black;
    }
  }
}
