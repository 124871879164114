.menu {
  &-main {
    @include box-shadow(0, 3px, 6px, #00000029);
    height: fit-content;
    border: 1px solid $main-color-light;
    display: flex;
    flex-direction: column;

    &-navbar {
      ul {
        border-bottom: 0.5px solid rgba(128, 128, 128, 0.541);
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        li {
          white-space: nowrap;
          flex: 1;
          text-align: center;
          padding: 10.8px;
          cursor: pointer;
          color: $primary-color;
          font-weight: 900;
          @include transition(all, 0.5s, ease);

          &:hover {
            background-color: $custom-lightblue;
            color: $main-color;
          }
        }
      }

      .bar-active {
        cursor: default;
        background-color: $custom-lightblue;
        color: $main-color;
      }
    }

    &-body {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;

      .element {
        user-select: none;
        cursor: grabbing;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        font-size: 12px;
        border: 1px solid $main-color-light;
        width: 90px;
        height: 80px;
        margin: 10px 13px;
        @include transition(all, 0.5s, ease);
        @include box-shadow(0, 3px, 6px, #00000029);

        &:hover {
          border: 1px solid lightblue;
          color: $custom-lightblue;
        }
      }

      .settings {
        width: 100%;
        padding: 10px;

        .image {
          &-preview {
            cursor: pointer;
            display: inline-flex;
            border: 1px solid gainsboro;
            justify-content: center;
            align-items: center;

            img {
              display: block;
              max-width: 230px;
              max-height: 95px;
              width: auto;
              height: auto;
            }

            .icon-wrapper {
              width: 115px;
              height: 80px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }

          &-source {
            display: flex;
            justify-content: left;
            align-items: center;
            margin: 10px 0;

            label {
              margin-right: 5px;
              font-size: 14px;
            }

            input {
              border-bottom: 1px solid #ddd;
              width: 240px;
            }
          }

          button {
            display: flex;
            width: fit-content;
            outline: none;
            background: none;
            color: #81d8f2;
            margin: 0;
            font-size: 13px;
            border: none;
          }
        }

        .slider {
          .image-uploader {
            .modal-preview {
              height: -webkit-fill-available;

              .image-preview {
                display: flex;
                height: 100%;
                max-height: 70px;

                img {
                  display: block;
                  max-width: 100%;
                  max-height: 100%;
                  height: auto;
                  width: auto;
                }
              }
            }

            .image-remove {
              cursor: pointer;
              color: #81d8f2;
              margin: 0 6px;
              font-size: 12px;
            }
          }

          .slider-images {
            display: flex;
            flex-flow: wrap;

            .image-uploader {
              padding: 5px;
              margin: 0;
              width: 20%;
            }
          }

          input[type="button"] {
            text-align: center;
            font: Regular 13px/23px Palanquin;
            letter-spacing: 0;
            color: #ffffff;
            background: #67676799 0 0 no-repeat padding-box;
            opacity: 1;
            @extend .custom-button;
          }
        }

        h3 {
          margin-top: 5px;
          color: black;
          text-transform: uppercase;
        }

        .textSettings {
          .rdw-editor-toolbar {
            .rdw-colorpicker-modal {
              left: -140px !important;
            }
          }

          .DraftEditor-root {
            word-break: break-all;
          }

          .hidden {
            display: none;
          }
        }

        .videoSettings,
        .audioSettings {
          color: rgb(87, 86, 86);
          font-size: 14px;

          .settings-checkbox {
            @extend %checkbox-style;

            & > span {
              right: -25px;
            }
          }

          &-source {
            display: flex;
            justify-content: left;
            align-items: center;
            margin: 10px 0;

            input {
              width: 240px;
            }
          }

          &-format {
            margin: 15px 0;
            color: inherit;
            width: 250px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            span {
              display: flex;
              justify-content: center;
              align-items: center;

              input[type="checkbox" i] {
                margin: 0 0 0 10px;
                width: 15px;
                height: 15px;
              }
            }
          }
        }

        .socialMedia {
          position: relative;

          &-configure {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 90%;

            &-style {
              display: flex;
              justify-content: left;
              align-items: center;

              label {
                margin: 0;
              }

              select {
                @extend .select-box;
                width: 120px;
                padding: 0;
                margin: 0 5px;
              }
            }

            &-icon {
              display: flex;
              justify-content: right;
              align-items: center;

              div {
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                border: 1px solid #70707031;
                cursor: pointer;
                margin: 0 10px;

                span {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }
              }

              .color,
              .gray {
                &-active {
                  background-color: lightgray;
                }
              }
            }
          }

          &-main {
            position: relative;
            width: 90%;
          }

          form {
            display: flex;
            flex-direction: column;
          }
          &-input {
            margin: 5px 0;
            display: flex;
            justify-content: left;
            align-items: center;
          }

          input[type="url"] {
            border-bottom: 1px solid #ddd;
            margin: 5px 5px 5px 5px;
            width: 95%;
          }

          input[type="button"] {
            @extend .custom-button;
            width: 85px;
            height: 30px;
            // margin: 5px 0;
            float: right;
            color: $main-color;
            border: 1px solid #707070;
            opacity: 1;
            background-color: white;
          }

          button {
            @extend .custom-button;
            text-align: center;
            font: Regular 13px/23px Palanquin;
            letter-spacing: 0;
            color: #ffffff;
            background: #67676799 0 0 no-repeat padding-box;
            opacity: 1;
          }
        }

        .map {
          form {
            .address {
              margin: 20px 0;

              input[type="text"] {
                width: 80%;
              }
            }

            .zoom {
              display: flex;
              justify-content: left;
              align-items: center;
              flex-wrap: wrap;
              margin: 20px 0;

              .map-slider {
                margin-left: 21px;
                width: 50%;
                display: inline-block;
              }
            }
          }
        }

        .link-button {
          form {
            display: flex;
            flex-direction: column;
            align-items: left;

            .link-button-row-1 {
              display: flex;
              justify-content: left;
              align-items: center;
              margin: 7px 0;

              input[type="text"] {
                width: 80%;
              }
            }

            .link-button-row-2 {
              margin: 7px 0;

              input[type="url"] {
                width: 80%;
              }
            }

            .link-button-row-3 {
              display: flex;
              justify-content: left;
              align-items: center;
              margin: 7px 0;

              label {
                font-size: 13px;
              }

              @extend .custom-color-input;
              select {
                @extend .select-box;
                width: 70px;
                margin-left: 10px;
              }

              input[type="number"] {
                width: 50px;
              }
            }
          }

          input[type="color"] {
            border: 1px solid black !important;
          }
        }

        .divider {
          form {
            display: flex;
            justify-content: left;
            align-items: center;
            padding: 20px 0;

            select {
              @extend .select-box;
              width: 100px;
              margin-right: 10px;
              padding: 0;
            }

            input[type="number"] {
              width: 100px;
              padding: 0;
            }

            @extend .custom-color-input;
          }
        }
      }
    }
  }
}

.breadcrumb {
  background-color: transparent;
  padding-left: 0;

  .breadcrumb-item {
    a {
      color: $primary-color;
    }
  }
}
