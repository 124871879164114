.table-ordered-column{
    cursor: pointer;
    .sorting-order-sign{
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      .active{
        color: #00acee;
      }
    }
}
