.main {
  * {
    box-sizing: border-box;
  }

  position: relative;
  margin: auto;
  margin-top: 20px;

  .back-linck {
    color: #1dbae8;
    font-weight: 600;
    &:hover {
      text-decoration: underline;
    }
  }

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  &-header {
    position: relative;
    margin: 10px 0;
    display: flex;
    justify-content: space-between;

    .btn {
      padding: 3px 20px;

      &-save-as {
        margin-right: 10px;
      }

      &-save-template {
        width: 150px;
        min-width: 150px;
      }
      &-preview {
        width: fit-content;
      }
    }

    .template-name {
      position: relative;
      width: 45%;
      input {
        width: 100%;
        font: Bold 18px/33px Palanquin;
      }
    }
  }
}
