//Mixins
@mixin transition(
  $what: transition-property,
  $time: transition-duration,
  $how: transition-timing-function
) {
  -webkit-transition: $what $time $how;
  -moz-transition: $what $time $how;
  -o-transition: $what $time $how;
  transition: $what $time $how;
}

@mixin border-radius($radius) {
  border-radius: $radius;
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
}

@mixin transform($transforms) {
  -webkit-transform: $transforms;
  -moz-transform: $transforms;
  -ms-transform: $transforms;
  transform: $transforms;
}

@mixin scale($scale) {
  @include transform(scale($scale));
}

@mixin rotate($deg) {
  @include transform(rotate(#{$deg}deg));
}

@mixin transform-origin($origin) {
  -webkit-transform-origin: $origin;
  -moz-transform-origin: $origin;
  -ms-transform-origin: $origin;
  transform-origin: $origin;
}

@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow: inset $top $left $blur $color;
    -moz-box-shadow: inset $top $left $blur $color;
    box-shadow: inset $top $left $blur $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $color;
    -moz-box-shadow: $top $left $blur $color;
    box-shadow: $top $left $blur $color;
  }
}

@mixin placeholderName($color) {
  &::-webkit-input-placeholder {
    /* WebKit browsers */
    color: $color;
  }
  &:-moz-placeholder {
    /* WebKit browsers */
    color: $color;
    opacity: 1;
  }
  &::-moz-placeholder {
    /* WebKit browsers */
    color: $color;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: $color;
  }
}

//Extend/Inheritance
%close-icon-style {
  position: absolute;
  display: inline-block;
  top: 0;
  right: 20px;
  font-size: 30px;
  color: $main-color;
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    color: $primary-color;
  }
}

%dropdown-toggle {
  border-top: 0.5em solid;
  border-right: 0.5em solid transparent;
  border-bottom: 0;
  border-left: 0.5em solid transparent;
}

%checkbox-style {
  & > input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    &:checked ~ span:after {
      display: block;
    }
  }
  & > span {
    cursor: pointer;
    border: 1px solid #d1d7e2;
    border-radius: 3px;
    background: #ffffff;
    position: absolute;
    top: 1px;
    right: 0;
    height: 18px;
    width: 18px;
    &:after {
      content: "";
      position: absolute;
      display: none;
      top: 50%;
      left: 50%;
      margin-left: -2px;
      margin-top: -6px;
      width: 5px;
      height: 10px;
      border: solid $primary-color;
      border-width: 0 2px 2px 0 !important;
      @include transform(rotate(#{45}deg));
    }
  }
}
