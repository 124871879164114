.dashboard {
  position: relative;
  background-color: $main-color;
  height: 750px;

  &-title {
    color: #ffff;
    padding: 10px 15px;
    h2 {
      font: Bold 14px/22px Palanquin;
      margin-bottom: 0;
    }
  }
  &-main {
    position: relative;
    background-color: #ffff;
    width: 100%;
    max-width: 380px;
    height: 90%;
    margin: 20px auto;
    border-radius: 30px;
    text-align: center;
    display: flex;
    flex-direction: column;
    color: #808080;
    font: Regular 14px/26px Palanquin;

    &-header {
      position: relative;
      height: fit-content;
      padding: 10px 5px 5px;

      .drag-container {
        width: 100%;
        cursor: "move";

        .drag-item {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 5px 0;
        }

        .logo {
          position: relative;
          overflow: hidden;
          cursor: auto;

          &-area {
            cursor: pointer;
          }

          .resizable {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          img {
            display: block;
            max-width: 100%;
            max-height: 260px;
            width: auto;
            height: 100%;
            border-bottom: 2px groove gray;
          }
        }

        .head-text {
          cursor: auto;
          white-space: nowrap;
          overflow-y: hidden;
          overflow-x: auto;
          text-overflow: ellipsis;
          text-align: left;
          width: 100%;

          &-preview {
            cursor: pointer;
            text-align: center;
          }

          @include scroll-bar;
          p {
            margin-bottom: 0;
          }
        }
      }
    }

    &-body {
      @include scroll-bar;
      position: relative;
      padding: 0 5px;
      height: 62%;
      border-top: 1px solid gray;
      border-bottom: 1px solid gray;
      display: flex;
      flex-direction: column;
      overflow-y: scroll;

      .body-name {
        position: absolute;
        margin-bottom: 0;
        left: 0;
        right: 0;
        top: 50%;
      }

      .resizable-container {
        .videoData {
          &-responsive {
            position: absolute;
            width: 100%;
            height: 100%;
          }
        }
      }

      .element {
        &-wrapper {
          overflow: unset;
          z-index: 2;

          margin: 15px 0;
          border: 1px solid transparent;

          &:hover {
            border: 1px solid #3b97e3;
            border-top: none;
          }

          &-active {
            border: 1px solid #3b97e3;
            outline: 1px solid #3b97e3;
            border-top: none;
          }

          .toolbar {
            height: 20px;
            margin: 0 0 5px;
            cursor: move;
            background: linear-gradient(180deg, $primary-color, transparent);
            .remove {
              font-size: 20px;
              margin-bottom: 5px;
              cursor: pointer;
              color: red;
              float: right;
            }
          }

          .videoData {
            &-initialView {
              display: flex;
              justify-content: center;
              align-items: center;
              color: red;
              height: 200px;
            }
          }

          .imageData {
            position: relative;
            height: 201px;

            &-initialView {
              display: flex;
              justify-content: center;
              align-items: center;
              color: red;
              height: 200px;
            }

            a {
              height: 100%;
              img {
                display: block;
                max-width: 100%;
                max-height: 100%;
                width: auto;
                height: auto;
              }
            }
          }

          .textData {
            all: initial;

            padding: 0 15px;
            display: contents;

            &-container {
              padding: 0 10px;
              max-height: 100%;
              overflow: auto;
              word-break: break-all;
            }

            ul {
              margin: 1em 0;
              list-style-type: disc;
              margin-left: 22px;
            }

            ol {
              margin-left: 15px;
              padding-left: 0;
            }
          }

          .linkButtonData {
            padding: 0;
            min-height: 32px;
            max-height: 150px;
            overflow: hidden;
            border: 1px solid #ccc;
            margin: 5px;

            a {
              display: inline-block;
              padding: 5px;
              text-decoration: none;
              min-width: 150px;
              min-height: 35px;
              width: 100%;
            }

            &-initial {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 52px;
            }
          }

          .socialMediaData {
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 45px;

            a {
              margin: 0 5px;
            }
          }

          .sliderData {
            position: relative;
            height: 201px;

            &-initialView {
              display: flex;
              justify-content: center;
              align-items: center;
              color: red;
              height: 200px;
            }

            .carousel {
              &-root,
              &-slider,
              .slider,
              div {
                height: 100%;
              }
              img {
                display: block;
                max-width: 100%;
                max-height: 100%;
                width: auto;
                height: auto;
              }

              li > div {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: white;
              }
            }
          }

          .mapData {
            height: 100%;

            &-initialView {
              display: flex;
              justify-content: center;
              align-items: center;
              color: red;
            }
          }

          .audioData {
            height: 100%;
            iframe {
              body:-webkit-full-page-media {
                background-color: white;
              }
            }
          }
        }
      }
    }
    &-footer {
      position: relative;
      height: fit-content;
      padding: 5px;

      .drag-container {
        width: 100%;
        cursor: "move";

        .drag-item {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 5px 0;
        }

        .image {
          position: relative;
          overflow: hidden;
          cursor: auto;

          &-area {
            cursor: pointer;
          }

          .resizable {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          a {
            height: 100%;
            img {
              display: block;
              max-width: 100%;
              max-height: 260px;
              width: auto;
              height: 100%;
              border-top: 2px groove gray;
            }
          }
        }

        .foot-text {
          cursor: auto;
          white-space: nowrap;
          overflow-y: hidden;
          overflow-x: auto;
          text-overflow: ellipsis;
          // max-width: 100%;
          width: 100%;

          &-preview {
            cursor: pointer;
          }

          @include scroll-bar;
          p {
            margin-bottom: 0;
          }
        }
      }

      .socialMedia {
        cursor: pointer;
        .socialMediaData {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
