@import "../editor/editor_container";

@media (min-width: 992px) {
  .modal-lg, .modal-xl {
    max-width: 1000px;
  }
}

.modal-backdrop {
  background: #ffffff;
  &.show {
    opacity: 0.7;
  }
}

  .modal-content {
    @include box-shadow(0, 3px, 6px, #00000029);
    border: 1px solid #d1d1d1;
    border-radius: 0;
    text-align: center;
    .modal-header {
      border-bottom: 0;
    }
    .modal-footer {
      border-top: 0;
      justify-content: center;
      // margin-bottom: 50px;
    }
    .modal-title {
      font: Bold 16px/29px Palanquin;
    }
    .btn {
      padding: .375rem 2rem;
    }
    /*
    @include editorContainerStyle();
    */
  }

