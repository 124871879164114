.pagination {
  margin-bottom: 0;
  .page-item {
    .page-link {
      border: 0;
      padding: 0.3rem 0.75rem;
      border-radius: 3px;
      color: #1bbae7;
      &:focus {
        box-shadow: none;
      }
    }
    &.active {
      .page-link {
        color: #ffffff;
        background-color: #1bbae7;
      }
    }
  }
}
.pagination-options-size-changer{
  margin-left: 15px;
  &:focus-visible{
    outline: 1px solid #1bbae7;
  }
}
