// Global Config
* {
  padding: 0;
  margin: 0;
}

*:focus:active {
  outline: none;
}

body,
select,
textarea,
input,
button {
  font-family: "palanquin-regular", "sans-serif";
  font-size: 14px;
  color: $main-color;
}

.fbold,
b,
strong {
  font-family: "palanquin-bold", "sans-serif";
}

.flight {
  font-family: "palanquin-light", "sans-serif";
}

.fmedium {
  font-family: "palanquin-medium", "sans-serif";
}

.text-primary {
  color: #1bbae7 !important;
}

img {
  max-width: 100%;
  vertical-align: middle;
}

.btn {
  border-radius: 0;
  @include transition(all, 0.5s, ease);
  &:not(:disabled):not(.disabled).active:focus,
  &:not(:disabled):not(.disabled):active:focus,
  &:focus {
    box-shadow: none;
  }
  &.btn-primary {
    background-color: #1bbae7;
    border-color: #1bbae7;
    color: #ffffff;
  }
  &.btn-dark {
    background-color: #67676799;
    border-color: #67676799;
    color: #ffffff;
  }
  &.btn-gray {
    background-color: #aaaaaa;
    border-color: #aaaaaa;
    color: #ffffff;
  }
  &.btn-light {
    background-color: #e6e6e6;
    border-color: #e6e6e6;
    color: $main-color;
  }
  &.btn-primary:not(:disabled):not(.disabled).active,
  &.btn-primary:not(:disabled):not(.disabled):active,
  &.btn-primary:not(:disabled):not(.disabled):focus {
    background-color: darken(#1bbae7, 10%);
    border-color: darken(#1bbae7, 10%);
  }
  &.btn-outline-secondary:not(:disabled):not(.disabled).active,
  &.btn-outline-secondary:not(:disabled):not(.disabled):active,
  &.btn-outline-secondary:not(:disabled):not(.disabled):focus {
    color: white;
    background-color: darken(#7f817f, 10%);
    border-color: darken(#7f817f, 10%);
  }
  &.btn-dark:not(:disabled):not(.disabled).active,
  &.btn-dark:not(:disabled):not(.disabled):active,
  &.btn-dark:not(:disabled):not(.disabled):focus {
    background-color: darken(#67676799, 10%);
    border-color: darken(#67676799, 10%);
  }
  &.btn-gray:not(:disabled):not(.disabled).active,
  &.btn-gray:not(:disabled):not(.disabled):active,
  &.btn-gray:not(:disabled):not(.disabled):focus {
    background-color: darken(#67676799, 10%);
    border-color: darken(#67676799, 10%);
  }
  &.btn-light:not(:disabled):not(.disabled).active,
  &.btn-light:not(:disabled):not(.disabled):active,
  &.btn-light:not(:disabled):not(.disabled):focus {
    background-color: darken(#e6e6e6, 30%);
    border-color: darken(#e6e6e6, 30%);
    color: #e6e6e6;
  }
}

.dropdown-item.active,
.dropdown-item:active {
  color: #1bbae7;
  text-decoration: none;
  background-color: #ffffff;
}

.form-control {
  border-radius: 0;
  &:focus,
  &.active {
    box-shadow: none;
  }
}

a {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

.table {
  .thead-dark th {
    color: #fff;
    background-color: $main-color;
    border-color: $main-color;
  }
  td,
  th {
    text-align: center;
    vertical-align: middle;
  }
}
.btn.disabled,
.btn:disabled,
.disabled {
  opacity: 0.2;
  pointer-events: none;
  cursor: auto;
}

.eventDisabled {
  pointer-events: none;
}

.none {
  display: none !important;
}

.hidden {
  visibility: hidden;
}

.not-allowed {
  cursor: not-allowed !important;
}

select {
  border-color: #c7ccd1;
  cursor: pointer;
  @include transition(border-color, 0.5s, ease);
  &:hover {
    border-color: #1bbae7;
  }
  &:focus {
    box-shadow: none;
  }
}

.ay-checkbox {
  top: 2px;
  position: absolute;
  right: 3px;
  @extend %checkbox-style;
}

.navbar-toggler:focus {
  outline: none;
}
