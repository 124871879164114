.editor-container {
  .editor-title {
    font: Bold 16px/22px Palanquin;
  }

  .file-editor {
    min-height: 312px;
    height: 100%;
    @include box-shadow(0, 3px, 6px, #00000029);
    border: 1px solid $main-color-light;

    .image-box {
      .assets-build-box {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;

        .image-container {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          background: #e8e8e8;
          border: 1px solid #909090;
          padding: 8px;
          height: 150px;

          div {
            width: fit-content;
            margin: 0 auto;

            .assets-checkbox {
              right: -9px;
              top: -12px;
            }

            img {
              height: auto;
              max-height: 130px;
            }
          }

          &.checked {
            border: 2px solid #1dbae8;
          }
        }
      }
    }
  }

  .folder-editor {
    background-color: $main-color;
    height: 100%;
    text-align: left;

    .folder-btn-group {
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    }
  }

  .add-btn {
    min-width: 142px;
    font: 14px/25px Palanquin;
  }

  .editor-placeholder {
    padding: 24px;
    opacity: 0.6;

    p {
      margin-bottom: 0;
    }
  }

  .project-add-editor {
    .file-editor {
      padding: 60px 105px 20px;
      position: relative;
    }

    .editor-text {
      font: Regular 14px/22px Palanquin;
    }

    .close-icon {
      @extend %close-icon-style;
    }

    .project-add-box {
      input {
        border: 0;
        border-bottom: 1px solid #c7ccd1;
        border-radius: 0;
        width: 100%;
        padding: 5px 5px 0;
        @include transition(border-bottom-color, 0.5s, ease);
      }

      .focus-effect ~ .focus-border {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 2px;
        background-color: #1dbae8;
        @include transition(width, 0.5s, ease);
      }

      .focus-effect:focus ~ .focus-border {
        width: 100%;
        @include transition(width, 0.5s, ease);
      }

      .btn-primary {
        color: #fff;
        background-color: #1dbae8;
        border: none;
        min-width: 155px;
        border-radius: 0;
        font: Bold 16px/29px Palanquin;
        letter-spacing: 0.01px;
      }

      .upload-text {
        font: Regular 12px/22px Palanquin;
        opacity: 0.5;
      }
    }
  }

  .project-overview-editor,
  .overview-editor {
    .nav-tabs {
      border: 0;
      background-color: #67676733;

      .nav-item {
        text-transform: uppercase;

        &:not(:last-child) {
          margin-right: 40px;
        }

        &.tabs-title {
          padding-top: 5px;
          margin-right: 48px;
          font: Bold 16px/29px Palanquin;
          color: #818181;
        }

        .nav-link {
          border: 0;
          color: $primary-color;

          &.active {
            background-color: transparent;
            //text-decoration: underline;
          }
        }

      }
    }

    .project-overview-row {
      margin-bottom: 270px;

      .card {
        border-radius: 0;
        text-transform: uppercase;

        .card-title {
          font: Bold 15px/27px Palanquin;
          color: $main-color;
          max-height: 27px;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .card-img-number {
          white-space: nowrap;
        }
        .card-text {
          font: 13px/23px Palanquin;
          margin-bottom: 0;
          color: $main-color;
        }

        .card-link + .card-link {
          margin-left: 0;
        }

        .card-link {
          color: $primary-color;
        }

        .card-footer {
          background-color: #67676733;
        }

        .card-img {
          height: 156px;
          overflow: hidden;

          img {
            border-radius: 0;
          }
        }
      }
    }
  }

  .project-overview-wrapper {
    max-height: calc(100vh - 150px);
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 6px;
      background-color: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $primary-color;
      border-radius: 10px;
    }
  }

  .project-list-editor {
    .btn-outline-secondary {
      border-radius: 0;
      min-width: 85px;
      padding: 3px 20px;

      &:not(:last-child) {
        margin-right: 10px;
      }
    }

    .editor-title {
      font: Bold 18px/33px Palanquin;
    }

    .project-list-btn-wrapper {
      min-width: -webkit-fit-content;
      min-width: -moz-fit-content;
      min-width: fit-content;
    }

    .project-overview-editor {
      position: relative;
    }

    .add-file-editor {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.95);

      .close-icon {
        @extend %close-icon-style;
        margin-right: 10px;
      }
    }
  }

  .project-view,
  .editor-view {
    .nav-tabs {
      background-color: transparent;

      .nav-item {
        &.tabs-title {
          font: 14px/22px Palanquin;
        }

        &:not(:last-child) {
          margin-right: 15px;
        }

        .nav-link {
          padding: 5px 0 5px 8px;
          color: $main-color;

          &:hover,
          &.active {
            color: $primary-color;
          }
        }
      }
    }

    .nav-link {
      padding: 5px 0 5px 8px;
      color: $main-color;
      @include transition(color, 0.5s, ease);

      &:hover,
      &.active {
        color: $primary-color;
      }
    }

    .dropdown {
      &.nav-item:not(:last-child) {
        margin-right: 25px;
      }

      .dropdown-toggle::after {
        @extend %dropdown-toggle;
        margin-left: 0.9em;
        vertical-align: 0.1em;
      }
    }

    .project-overview-row {
      margin-bottom: 0;

      .card {
        border: 0;

        .card-footer {
          background-color: transparent;
          font-size: 12px;
        }
      }
    }

    .template-box {
      max-width: 320px;
      margin: 20px auto;

      .template-title {
        font: 13px/22px Palanquin;
      }

      .template-group {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        .template {
          width: 53px;
          height: 82px;
          background-color: rgba(103, 103, 103, 0.4);
          line-height: 82px;
          text-align: center;
          color: white;
          font-size: 8px;
          text-transform: uppercase;

          &.active {
            background-color: #15bae7;
          }
        }
      }
    }

    .images {
      border: 1px solid #707070;
      width: 115px;
      min-height: 70px;
      line-height: 70px;
      text-align: center;
    }

    .btn {
      padding: 3px 20px;
    }

    &.assets-view {
      .btn {
        padding: 0.375rem 2rem;
      }

      .list-group {
        .list-group-item {
          &.parent-list-group-item {
            .control-btns {
              position: absolute;
              right: 10px;
              top: 0;

              .btn {
                padding: 0 10px 5px;
              }
            }
          }

          padding: 0.25rem 0.6rem;
          background-color: $main-color;
          border: 0;

          .list-group-link {
            color: #ffffff;
            padding-left: 30px;
            display: inline-block;

            &.image-name {
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          .parent-list-group-link {
            cursor: pointer;
            position: relative;
            display: block;
            padding-right: 120px;

            &:active,
            &.active {
              text-decoration: underline;
            }

            &.active:before {
              top: 12px;
              @include transform(rotate(#{0}deg));
            }

            &:before {
              content: "";
              position: absolute;
              @extend %dropdown-toggle;
              left: 0;
              top: 8px;
              @include transform(rotate(#{270}deg));
            }
          }
        }
      }

      .drag-box .btn {
        padding: 3px 20px;
      }

      .assets-checkbox {
        position: absolute;
        right: 3px;

        & > input {
          cursor: pointer;
          border: 1px solid #d1d7e2;
          border-radius: 3px;
          background: white;

          height: 18px;
          width: 18px;
        }
      }
    }

    select {
      height: 40px;
      padding-top: 0;
      border-color: #7070704d;
    }
  }

  .project-view-slider {
    .card {
      width: 340px;
      margin: 0 auto;

      img {
        max-width: 100%;
        border: 1px solid #f5f6f7;
      }
    }

    .slider-control-icon {
      position: absolute;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;

      &.slider-control-prev {
        left: -25px;
      }

      &.slider-control-next {
        right: -25px;
      }

      .btn {
        color: #707070;
        font-size: 50px;
        padding: 0;
      }
    }
  }

  .templates-view {
    input {
      border: 0;
      border-bottom: 1px solid #c7ccd1;
      background-color: transparent;
      border-radius: 0;
      width: 100%;
      padding: 5px 5px 0;
      color: #c7ccd1;
      @include transition(border-bottom-color, 0.5s, ease);
      @include placeholderName(rgba(255, 255, 255, 0.5));
    }

    .focus-effect ~ .focus-border {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 2px;
      background-color: #1dbae8;
      @include transition(width, 0.5s, ease);
    }

    .focus-effect:focus ~ .focus-border {
      width: 100%;
      @include transition(width, 0.5s, ease);
    }

    .edit-folder {
      input {
        color: $main-color;
        @include placeholderName(rgba(103, 103, 103, 0.5));
        border-bottom: 1px solid #1dbae8;
      }
    }

    .template-build-box {
      width: 60px;

      &--inner {
        width: 100%;
        height: 90px;
        border-radius: 6px;
        margin: 2px auto;
        @include box-shadow(0, 3px, 6px, #00000029);
      }

      p {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
      }
    }
  }

  .table-hover tbody tr:hover {
    color: #212529;
    background-color: rgba(0, 0, 0, 0.02);
  }

  .user-btn-action {
    white-space: nowrap;

    .btn {
      border-radius: 0.25rem;
    }
  }

  .sorting-tools-wrapper{
    display: flex;
    align-items: center;
    height: inherit;
    margin-right: 15px;
    .sorting-item{
      display: flex;
      align-items: center;
      margin-left: 10px;
      cursor: pointer;
      .sorting-order-sign{
          display: flex;
          align-items: center;
          margin-right: 10px;
       }
    }
    .active{
      color: #00acee;
    }
  }

}
