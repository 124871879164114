@mixin scroll-bar {
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  }

  &::-webkit-scrollbar {
    height: 7px;
    width: 10px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #fff;
    background-image: -webkit-linear-gradient(
      top,
      #e4f5fc 0%,
      #bfe8f9 50%,
      #9fd8ef 51%,
      #2ab0ed 100%
    );
  }
}

.select-box {
  color: inherit;
  cursor: pointer;
  outline: none;
  padding: 2px;
  font-size: 14px;
  line-height: 1em;
  border: none;
  text-align: left;
  height: 25px;
  overflow: hidden;
  background-color: #fff;
  border-bottom: 1px solid #ddd;
}

.custom-input {
  color: inherit;
  border: none;
  outline: none;
  line-height: 1em;
  font-size: 14px;
  padding: 5px;
  text-align: left;
  width: 100px;
  border-bottom: 1px solid #ddd;
}

.custom-button {
  color: inherit;
  border: none;
  outline: none;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 13px;
}

.custom-color-input {
  input[type="color"] {
    outline: none;
    -webkit-appearance: none;
    margin-right: 15px;
    margin-left: 0;
    border: none;
    width: 40px;
    height: 22px;
  }

  input[type="color"]::-webkit-color-swatch-wrapper {
    padding: 0;
    margin: 0;
  }

  input[type="color"]::-webkit-color-swatch {
    border: none;
  }
}

.social-media-icons {
  a,
  span {
    text-align: center;
    vertical-align: middle;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .gray {
    color: gray !important;
  }

  .active {
    cursor: pointer;
  }

  .Twitter {
    &-Solid {
      color: #00acee;
      font-size: 25px;
    }

    &-Outlined {
      color: #00acee;
      font-size: 25px;
    }
  }

  .Instagram {
    &-Solid {
      color: #d55e54;
      font-size: 30px;
    }

    &-Outlined {
      color: #d55e54;
      font-size: 30px;
    }
  }

  .Youtube {
    &-Solid {
      color: #c4302b;
      font-size: 32px;
    }

    &-Outlined {
      font-size: 30px;
      color: #c4302b;
    }
  }

  .Facebook {
    &-Solid {
      color: #3b5998;
      font-size: 25px;
    }

    &-Outlined {
      color: #3b5998;
      font-size: 35px;
    }
  }

  .Pinterest {
    &-Solid {
      color: #e60023;
      font-size: 25px;
    }

    &-Outlined {
      color: #e60023;
      font-size: 30px;
    }
  }

  .LinkedIn {
    &-Solid {
      color: #0077b5;
      font-size: 25px;
    }

    &-Outlined {
      color: #0077b5;
      font-size: 25px;
    }
  }
}
