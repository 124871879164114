.editor-container,
.project-overview-editor {
  .project-img-box {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 188px;

    img {
      display: block;
      max-width: 100%;
      max-height: 188px;
      width: auto;
      height: auto;
    }
  }
}

.project-view {
  .textSettings {
    all: initial;

    .rdw-editor-toolbar {
      .rdw-colorpicker-modal {
        left: -140px !important;
      }
    }
  }

  .project-img-box {
    img {
      display: block;
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
    }
  }

  .form-group {
    .image-preview {
      display: inline-flex;
      align-items: center;
      cursor: pointer;

      .modal-preview {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        height: 150px;
        border: 1px solid lightgray;

        img {
          display: block;
          max-height: 100%;
          max-width: 100%;
          height: auto;
          width: auto;
        }
      }
    }

    .slider-images {
      display: flex;
      flex-wrap: wrap;

      .image-uploader {
        display: flex;
        flex-direction: column;
        margin: 0 5px;

        svg {
          font-size: 25px;
          cursor: pointer;
        }

        .image-preview {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100px;
          height: 150px;
          border: 1px solid lightgray;

          img {
            display: block;
            max-height: 100%;
            max-width: 100%;
            height: auto;
            width: auto;
          }
        }
      }
    }

    .socialMedia {
      .delete-row {
        display: inline-flex;
      }
    }
  }

  .submit-screen-data {
    display: flex;
    width: 100%;
    max-width: 300px;
    background: #fff;
    padding: 15px 0;

    button:first-child {
      margin-right: 10px;
    }

    button {
      width: 100%;
    }
  }

  .border-bottom {
    .col {
      .textSettings {
        all: none;
        max-width: 544px;

        .editor {
          border: 1px solid rgba(128, 128, 128, 0.2);
          margin: 0;
          min-height: 80px;
          padding: 5px;
          box-shadow: 2px 3px 4px rgba(128, 128, 128, 0.2);
        }
      }
    }
  }
}
