.dnd-container {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border: 1px dashed $primary-color;
  box-sizing: border-box;
  padding: 20px;
  cursor: pointer;
  @include box-shadow(0, 2px, 3px, #0000001a);
  .dropzone {
    &:focus {
      outline: none;
    }
  }
}
