body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.text-blue {
  color: $hover-color-primary;
}
a.text-blue:active,
a.text-blue:focus,
a.text-blue:hover {
  color: $primary-color;
}

/*Not found*/
.not-found-404 {
  line-height: 0.8;
  left: 0;
  right: 0;
  top: 0;
  font-size: 260px;
}

.not-found-text {
  font-size: 25px;
}

.popup-message {
  position: fixed;
  bottom: 30px;
  right: -200px;
  padding: 5px 20px;
  background-color: aliceblue;
  transition-property: right;
  transition-duration: 2s;

  &-open {
    position: fixed;
    bottom: 30px;
    right: 30px;
    padding: 5px 20px;
    background-color: aliceblue;
    transition-property: right;
    transition-duration: 2s;
  }
}

.ay-header {
  .navbar-light .navbar-nav {
    .nav-link {
      color: $main-color;
      text-align: left;
      line-height: 22px;
      letter-spacing: 0;
      @include transition(color, 0.3s, ease);
      &:focus {
        color: $primary-color;
      }
      &:not(.dropdown):not(:last-child):not(.dropdown-toggle) {
        margin-right: 45px;
      }
    }
    .active > .nav-link,
    .nav-link.active,
    .nav-link:active,
    .nav-link.show,
    .show > .nav-link {
      color: $primary-color;
      cursor: default;
    }
  }
  .dropdown-navbar {
    .dropdown {
      .dropdown-menu {
        text-align: left;
        .btn {
          border-radius: 0.25rem;
        }
      }
    }
  }
  .navbar-brand {
    margin-right: 160px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .ay-navbar {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .ay-navbar_shadow {
    @include box-shadow(0, 3px, 6px, #00000029);
  }
  .dropdown-toggle::after {
    @extend %dropdown-toggle;
    margin-left: 0.9em;
    vertical-align: 0.1em;
  }
}

.project-breadcrumb {
  position: -webkit-sticky;
  position: sticky;
  top: 80px;
  z-index: 10;
  background-color: #fff;
}

.ay-main {
  margin-top: 100px;
  margin-bottom: 30px;
  .login-container {
    padding-top: 30px;
    .login-box {
      @include box-shadow(0, 3px, 6px, #00000029);
      max-width: 422px;
      padding: 30px 60px 60px;
      .form-group {
        position: relative;
      }
      input {
        border: 0;
        border-bottom: 1px solid #c7ccd1;
        border-radius: 0;
        width: 100%;
        padding: 5px 5px 0;
        @include transition(border-bottom-color, 0.5s, ease);
        @include placeholderName(rgba(103, 103, 103, 0.5));
      }
      .focus-effect ~ .focus-border {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 2px;
        background-color: #1dbae8;
        @include transition(width, 0.5s, ease);
      }
      .focus-effect:focus ~ .focus-border {
        width: 100%;
        @include transition(width, 0.5s, ease);
      }
      .login-title {
        color: $main-color;
        margin-bottom: 60px;
        font-size: 20px;
      }
      .login-text {
        color: $main-color;
        margin-bottom: 30px;
        display: inline-block;
        @include transition(color, 0.5s, ease);
      }
      .btn-primary {
        color: #fff;
        background-color: #1dbae8;
        border: none;
        min-width: 155px;
        border-radius: 0;
        font: Bold 16px/29px Palanquin;
        letter-spacing: 0.01px;
        @include transition(all, 0.5s, ease);
      }

      .profile-img-box {
        width: 200px;
        margin: 0 auto 20px;
        img {
          max-width: 100%;
        }
      }

      .avatar-label {
        font: Bold 18px/22px Palanquin;
        cursor: pointer;
        @include transition(color, 0.3s, ease);
      }
    }
  }
  .info-container {
    .info-section {
      margin-bottom: 52px;
      .info-title {
        font: Bold 20px/36px Palanquin;
        margin-bottom: 0;
      }
      .info-text {
        font: Regular 14px/22px Palanquin;
      }
    }
  }

  .tags-container {
    .nav-tabs {
      .nav-link {
        @include transition(color, 0.3s, ease);
        color: $main-color;
        &.active {
          color: $primary-color;
          cursor: default;
        }
        &:not(.active):hover {
          border-color: transparent;
          color: $primary-color;
        }
      }
    }
    .tags-table {
      table {
        border: 1px solid;
        table-layout: fixed;
        td,
        th {
          border-top: 0;
        }
        tbody tr {
          border: 1px solid lightgray;
          td {
            border-left: 1px solid lightgray;
          }
        }
      }
    }
  }

  .previous-link {
    font: 14px/22px Palanquin;
    color: #1bbae7;
    @include transition(color, 0.5s, ease);
  }

  .cursor-pointer {
    cursor: pointer;
  }
}

.loader-table-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #333;
  opacity: 0.2;
}
