@media (min-width: 576px) {
  .modal-dialog {
    margin: 10rem auto 1.75rem;
  }
}

@media (min-width: 768px) {
  .ay-header {
    .navbar-light .navbar-nav {
      .nav-link:hover {
        color: $primary-color;
      }
    }
  }

  .ay-main {
    .login-container {
      .login-box {
        .btn-primary:not(:disabled):not(.disabled):hover {
          background-color: darken(#1bbae7, 10%);
          border-color: darken(#1bbae7, 10%);
        }
        .login-text:hover,
        .avatar-label:hover {
          text-decoration: none;
          color: $primary-color;
        }
        .form-control:hover {
          border-bottom-color: $primary-color;
        }
      }
    }

    .editor-container {
      .project-add-box {
        .form-control:hover {
          border-bottom-color: $primary-color;
        }
      }
      .project-list-editor {
        .previous-link:hover {
          color: darken(#1bbae7, 10%);
        }
      }
    }
    .assets-view {
      .list-group-link:not(:disabled):not(.active):hover {
        text-decoration: underline;
      }
    }
  }

  .btn-primary:not(:disabled):not(.disabled):hover {
    background-color: darken(#1bbae7, 10%);
    border-color: darken(#1bbae7, 10%);
  }
  .btn-dark:not(:disabled):not(.disabled):hover {
    background-color: darken(#67676799, 10%);
    border-color: darken(#67676799, 10%);
  }
  .btn-gray:not(:disabled):not(.disabled):hover {
    background-color: darken(#aaaaaa, 10%);
    border-color: darken(#aaaaaa, 10%);
  }
  .btn-light:not(:disabled):not(.disabled):hover {
    background-color: darken(#e6e6e6, 30%);
    border-color: darken(#e6e6e6, 30%);
    color: #e6e6e6;
  }

  .project-overview-row {
    .card:hover {
      box-shadow: 0 8px 16px #f2f2f2;
    }
  }
}

@media (max-width: 991px) {
  .ay-navbar {
    .navbar-collapse {
      border-top: 1px solid #ededed;
      margin-top: 20px;
    }
  }
  .ay-header .navbar-light .navbar-nav {
    .nav-link {
      padding-left: 10px;
      border-bottom: 1px solid #f5f5f5;
      @include transition(all, 0.3s, ease);
    }
    .nav-link:not(.dropdown):not(:last-child):not(.dropdown-toggle) {
      margin-right: 0;
    }
    .nav-link:hover,
    .nav-link.active,
    .show > .nav-link {
      background-color: #f5f5f542;
      border-color: #8fdef5;
    }
    .nav-link.active {
      cursor: default;
    }
  }
}

@media (max-width: 767px) {
  .ay-main {
    .editor-container {
      .project-add-editor {
        .file-editor {
          padding: 40px 15px;
        }
      }
      .project-overview-editor {
        .nav-tabs {
          .nav-item {
            &:not(:last-child) {
              margin-right: 0 !important;
            }
            &.tabs-title {
              padding-left: 15px;
            }
          }
        }
      }
    }
  }

  .not-found {
    img {
      width: 150px;
    }
    .not-found-404 {
      font-size: 170px;
    }
  }
}

@media (max-width: 400px) {
  .ay-header {
    .navbar-brand {
      margin-right: 0;
    }
  }

  .ay-main {
    .login-container {
      .login-box {
        padding: 15px;
        .login-title {
          margin-bottom: 20px;
        }
      }
    }
    .editor-container {
      .project-overview-editor {
        .nav-tabs {
          .nav-item {
            &.tabs-title {
              width: 100%;
            }
          }
        }
      }
      .project-view,
      .editor-view {
        .nav-tabs {
          .nav-item {
            &.tabs-title {
              width: auto;
            }
          }
        }
      }
      .project-list-editor {
        .btn-outline-secondary {
          padding: 3px 15px;
        }
      }
      .project-view {
        .images-group {
          flex-direction: column;
          .images {
            width: 100%;
          }
        }
      }
    }

    .assets-navbar {
      flex-direction: column;
      align-items: inherit !important;
      .col-auto {
        margin-bottom: 10px;
        margin-top: 10px;
      }
    }
  }
}

@media (max-width: 450px) {
  .dashboard {
    background-color: #ffff;

    &-title {
      color: #969696;
    }
    &-main {
      border: 1px solid #969696;
    }
  }
}

@media print {
  .container {
    width: auto;
  }
}
